import { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { API_DOMAIN } from "./constants";
import { AuthContext } from "./AuthContext";

export const useSocket = () => {
    const { authState } = useContext(AuthContext);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!authState?.id) return; // Wait until userId is available

        const newSocket = io(API_DOMAIN, {
            auth: { userId: authState?.id },
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
        });

        newSocket.on("connect", () => {
            console.log(`✅ Connected: ${newSocket.id}`);
        });

        newSocket.on("disconnect", () => {
            console.log("❌ Disconnected from server.");
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, [authState?.id]); // Dependency ensures socket is initialized only when authState.id changes

    return socket;
};
