import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../AuthContext";

// For the material modal
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { API_DOMAIN } from "../constants";
import useSnackbar from "../Snackbar/UseSnackbar";

// Modals styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: 6,
    boxShadow: 24,
    p: 4,
};

function LoginForm() {
    let navigate = useNavigate();

    const initialValues = {
        username: "",
        password: "",
    };

    const { setAuthState } = useContext(AuthContext);

    // validation of the fields
    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3).max(20).required("You must input a username"),
        password: Yup.string().min(4).max(20).required("You must input a password"),
    });

    // These here are for the Modal that displays awaiting approval
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();


    const onSubmit = (data) => {

        axios.post(API_DOMAIN + "/auth/login", data).then((res) => {
            if (res.data.error) {
                if (res.data.error === "approval") {
                    setOpen(true);
                } else {
                    ErrorSnackbar(res.data.error);
                }
            } else {
                SuccessSnackbar("Login Success!");

                console.log("res.data = " + JSON.stringify(res.data));

                localStorage.setItem("accessToken", res.data.token);

                setAuthState({
                    id: res.data.id,
                    username: res.data.username,
                    name: res.data.name,
                    status: true,
                    admin: res.data.admin,
                    wati_token: res.data.wati_token,
                    taxnumber: res.data.taxnumber,
                    wati_msg_approval: res.data.wati_msg_approval,
                });

                // if (res.data.username === "admin") {
                setTimeout(() => {
                    navigate("/querysetv2");
                }, 1000);
                // }
                // else {
                //     navigate("/queryset");
                // }
            }
        });
    };

    return (
        <div className="createItemPage">
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Form className="formContainer gradient-custom">
                    <label>Username: </label>
                    <ErrorMessage name="username" component="span" />
                    <Field id="inputCreateItem" name="username" placeholder="Username" />
                    <label>Password: </label>
                    <ErrorMessage name="password" component="span" />
                    <Field
                        id="inputCreateItem"
                        name="password"
                        type="password"
                        placeholder="*****"
                    />

                    <button type="submit" style={{ alignSelf: "flex-end" }}>
                        Login
                    </button>
                </Form>
            </Formik>

            {/* Modal for the application approval  */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Your Login is Pending Approval
                    </Typography>
                    <img
                        alt="Approval"
                        className="approval_photo"
                        src="https://codenex.in/wp-content/uploads/2019/01/appdevelopment.png"
                    />
                    <Typography variant="h6" id="modal-modal-description" sx={{ mt: 2 }}>
                        You will be able to sign in and use the services, as soon as your
                        profile has been seen and approved by the administrator!
                    </Typography>
                </Box>
            </Modal>

            <ToastContainer />
        </div>
    );
}

export default LoginForm;
