import { Button, ConfigProvider, Divider, Modal, Select, Switch, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { API_DOMAIN } from '../constants';
import useSnackbar from '../Snackbar/UseSnackbar';
import { AuthContext } from '../AuthContext';

const settingsOptions = [
    { id: 1, name: "Query Status Colors" },
    { id: 2, name: "WATI Round-Robbin" },
    { id: 3, name: "Query Images Watermark" },
    { id: 4, name: "WATI Message Approval" },
];

const SettingsModal = ({ open_settings, handleCloseSettingsModal, statuses, save }) => {

    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

    const { authState } = useContext(AuthContext);

    const [selectedOption, setSelectedOption] = useState(settingsOptions[0].id);
    const [loading, setLoading] = useState(false);
    const [robbin, setRobbin] = useState(false);
    const [statusColors, setStatusColors] = useState([]);

    const [assignableUsers, setAssignableUsers] = useState([]);
    const load_assignable_users = async () => {
        try {
            var response = await axios.post(API_DOMAIN + "/whatsapp/get_misc_mode", { mode: "wati_operators_online_status" });
            if (response.data.success) {
                setAssignableUsers(response.data.data);
            } else {
                alert("Failed to get Operators Info! Try again later\n");
            }
        } catch (error) {
            alert("Failed to get Operators Info! Try again later\n", error.message);
        }
    };

    const [wati_approval_options, setWati_approval_options] = useState([]);
    const load_approval_options = async () => {
        try {
            var response = await axios.post(API_DOMAIN + "/whatsapp/get_misc_mode", { mode: "wati_message_forward_approval" });
            if (response.data.success) {
                setWati_approval_options(response.data.data);
            } else {
                WarningSnackbar("Failed to get Operators Info! Try again later\n");
            }
        } catch (error) {
            WarningSnackbar("Failed to get Operators Info! Try again later\n", error.message);
        }
    };

    const [users, setUsers] = useState([]);
    const load_all_users = async () => {
        try {
            var response = await axios.get(API_DOMAIN + "/auth/get_users_for_settings");
            if (response.data.success) {
                setUsers(response.data.data);
            } else {
                WarningSnackbar("Failed to get Operators Info! Try again later\n");
            }
        } catch (error) {
            WarningSnackbar("Failed to get Operators Info! Try again later\n", error.message);
        }
    };

    const load_auto_function_status = async () => {
        try {
            var response = await axios.post(API_DOMAIN + "/whatsapp/get_misc_mode", { mode: "round_robbin_active" });
            if (response.data.success) {
                setRobbin(response?.data?.data?.active || false);
            } else {
                alert("Failed to get Auto-Assigning function status");
            }
        } catch (error) {
            alert("Failed to get Auto-Assigning function status! Try again later\n", error.message);
        }
    };

    const toggle_operator_assignable = (id, checkValue) => {
        setAssignableUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === id ? { ...user, assignable: checkValue } : user
            )
        );
    };


    useEffect(() => {
        if (open_settings) {
            load_assignable_users();
            load_auto_function_status();
            load_approval_options();
            load_all_users();
        }
    }, [open_settings])

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            setStatusColors(statuses);
        }
    }, [statuses]);

    const handleMenuClick = (id) => {
        setSelectedOption(id);
    };

    const handleRobbinToggle = (e) => {
        setRobbin(e)
    };

    //final save
    const save_settings = () => {
        try {
            setLoading(true)
            save(statusColors);
            save_operator_assignability();
            save_user_wati_approval_info();
            save_toggle_roundrobbin();
            SuccessSnackbar("Saved Settings Successfully!")
        } catch (error) {
            ErrorSnackbar("Saving settings failed!")

        }
        finally {
            setLoading(false)
        }
    }

    const handleSave = () => {
        if (window.confirm("Are you sure to save changes you made in the settings?")) {
            save_settings()
        }
    };

    const handleColorChange = (id, colour) => {
        setStatusColors(prevStatuses =>
            prevStatuses.map(status =>
                status.value === id ? { ...status, colour } : status
            )
        );
    };

    const save_operator_assignability = async () => {
        try {
            const res = await axios.post(API_DOMAIN + "/whatsapp/save_misc_mode", { mode: "wati_operators_online_status", moredetails: assignableUsers });
            if (res.data.success) {
                SuccessSnackbar("Saved settings successfully!")
            }
            else { ErrorSnackbar(res.data.message) }

        } catch (error) {
            ErrorSnackbar(error.message)
        }
        finally {
            setLoading(false);
        }
    }

    const save_toggle_roundrobbin = async () => {
        try {
            const res = await axios.post(API_DOMAIN + "/whatsapp/toggle_roundrobbin", { checked: robbin });
            if (res.data.success) {
                SuccessSnackbar(res.data.message)
                SuccessSnackbar("Saved settings successfully!")
            }
            else { ErrorSnackbar(res.data.message) }

        } catch (error) {
            ErrorSnackbar(error.message)
        }
        finally {
            setLoading(false);
        }
    }

    const change_wati_msg_approval_status = (value, id) => {
        setUsers(prevUsers =>
            prevUsers.map(user =>
                user.id === id ? { ...user, wati_msg_approval: value } : user
            )
        );
    }

    const save_user_wati_approval_info = async () => {
        try {
            const res = await axios.post(API_DOMAIN + "/auth/save_users_for_settings", { users: users });
            if (res.data.success) {
                SuccessSnackbar(res.data.message)
            }
            else { ErrorSnackbar(res.data.message) }

        } catch (error) {
            ErrorSnackbar(error.message)
        }
    }

    const renderDetails = () => {
        switch (selectedOption) {
            case 1:
                return (
                    <div>
                        <h4>Status Colors</h4>
                        <p>Change the colors of the status</p>
                        <div className='drop-shadow' style={{ display: 'flex', justifyContent: 'space-between', overflowY: "auto", height: "300px" }}>
                            <div>
                                <div className='border rounded p-2 drop-shadow' style={{ backgroundColor: "#fbfbfb", minWidth: "465px" }}>
                                    {statusColors.map((status) => (
                                        <div key={status.value} className='flex justify-between mt-1'>
                                            <div>
                                                <h6>{status.label}</h6>
                                            </div>
                                            <div>
                                                <input
                                                    type="color"
                                                    value={status?.colour || "gray"}
                                                    onChange={(e) => handleColorChange(status.value, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (< div className='p-2 drop-shadow' >
                    <h5>WATI Automated Round-Robbin Ticket Assignment</h5>
                    <Divider type='horizontal' className='p-0 m-0 mt-1 mb-2' style={{ backgroundColor: "black" }} />

                    <div className='border rounded p-2 mt-2 flex justify-between' style={{ backgroundColor: "" }}>
                        <div>Toggle Auto-Assign On/Off</div>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Switch: {
                                        handleBg: "#14b840",
                                        colorPrimary: "#63db83",
                                        colorPrimaryHover: "#408f55",
                                    },
                                },
                            }}
                        >
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                value={robbin}
                                onChange={handleRobbinToggle}
                            />
                        </ConfigProvider>
                    </div>

                    <div className='mt-2 p-2'>Set Assignability of Operators</div>
                    <Divider type='horizontal' className='p-0 m-0' style={{ backgroundColor: "black" }} />
                    <div className='border rounded mt-3 p-2 overflow-y-auto' style={{ height: "215px" }}>
                        <div className='mt-2'>
                            {assignableUsers?.length ? (
                                assignableUsers.map((element, index) => (
                                    <div className='rounded border p-2 m-2 flex justify-between' key={index} >
                                        <div>
                                            <div>
                                                {element.fullName}
                                            </div>
                                            <div style={{ fontSize: "12px", color: "gray" }}>
                                                {element.email ? element.email : (<i>&lt;no email id available&gt;</i>)}
                                            </div>
                                            <div style={{ fontSize: "12px", color: "gray" }}>
                                                {element?.roles.length > 0 ? element.roles.join(", ") : (<i>&lt;roles data not available&gt;</i>)}
                                            </div>
                                        </div>

                                        <div>
                                            {element?.email ? (
                                                <Switch
                                                    checkedChildren="On"
                                                    unCheckedChildren="Off"
                                                    value={element.assignable}
                                                    onChange={(e) => toggle_operator_assignable(element.id, e)}
                                                />
                                            ) :
                                                (<Tooltip placement="left" title="Bots are system managed, Can't assign to them directly.">
                                                    <Switch
                                                        checkedChildren="On"
                                                        unCheckedChildren="Off"
                                                        value={false}
                                                        disabled={true}
                                                    />
                                                </Tooltip>
                                                )}
                                        </div>
                                    </div>
                                ))
                            ) : null}
                        </div>
                    </div>
                    <div className='flex justify-center pt-1'><i style={{ color: "gray", fontSize: "10px" }}>Total agents including Bots : {assignableUsers.length}</i></div>
                </div >)
            case 3:
                return (
                    <div className='p-2 drop-shadow'>
                        <h5>Watermark Settings</h5>
                        <Divider type='horizontal' className='p-0 m-0 mt-1 mb-2' style={{ backgroundColor: "black" }} />

                        <div className='border rounded p-2'>
                            <div className='flex justify-between mb-2'>
                                <div>Set Photo Watermark</div>
                                <Tooltip title="Turn the feature on/off">
                                    <Switch
                                        checkedChildren="On"
                                        unCheckedChildren="Off"
                                        value={robbin}
                                        onChange={handleRobbinToggle}
                                    />
                                </Tooltip>
                            </div>
                            <Divider type='horizontal' className='p-0 m-0' style={{ backgroundColor: "black" }} />

                        </div>
                    </div>)
            case 4:
                return (<div className='p-2 drop-shadow'>
                    <h5>Set Who needs approval and who can approve</h5>
                    <Divider type='horizontal' className='p-0 m-0 mt-1 mb-3' style={{ backgroundColor: "black" }} />

                    <div className='border rounded p-2' style={{ height: "310px", overflowY: "auto" }}>
                        {users?.length > 0 ?
                            users.map((element) => {
                                return (
                                    <div className='border rounded p-2 flex justify-between mb-2'>
                                        <div>
                                            <div>
                                                {element.name}
                                            </div>
                                            <div>
                                                <i style={{ fontSize: "12px", color: "gray" }}>{element.email}</i>
                                            </div>
                                            <div>
                                                <i style={{ fontSize: "12px", color: "gray" }}>Admin :&nbsp;{element.admin ? "Yes" : "No"}</i>
                                            </div>
                                        </div>
                                        <div>
                                            <Select
                                                optionFilterProp="label"
                                                style={{ width: '100%' }}
                                                placeholder="Select Ability"
                                                value={element.wati_msg_approval}
                                                onChange={(e) => change_wati_msg_approval_status(e, element.id)}
                                                options={wati_approval_options}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                            : (
                                <div className='border rounded p-2 flex justify-between mb-2'>
                                    <i>No Users Found!!</i>
                                </div>
                            )}
                    </div>
                </div>)
            default:
                return <div>Keep calm, coffee is being converted to code...</div>;
        }
    };

    return (
        <Modal
            title="Settings"
            centered
            open={open_settings}
            onCancel={handleCloseSettingsModal}
            maskClosable={false}
            width={800}
            footer={
                <div className="flex justify-end">
                    <Button loading={loading} type="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </div>}
        >
            <div className='border rounded p-2 drop-shadow' style={{ backgroundColor: "#f7f7f7" }}>
                <div className='drop-shadow flex gap-3' style={{ display: 'flex', height: '400px' }}>
                    {/* Menu Pane */}
                    <div className='border rounded p-2' style={{ width: '200px', backgroundColor: "#fefefe" }}>
                        <div className='flex justify-center'>Menu Items</div>
                        <Divider type='horizontal' className='p-0 m-0 mt-1 mb-2' style={{ backgroundColor: "black" }} />
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {settingsOptions.map(option => (
                                <li key={option.id}
                                    style={{
                                        padding: '10px',
                                        borderRadius: "8px",
                                        cursor: 'pointer',
                                        backgroundColor: selectedOption === option.id ? '#404040' : 'transparent',
                                        transition: "background-color 0.3s, color 0.3s ease-in-out",
                                        color: selectedOption === option.id ? 'white' : 'black',
                                    }}
                                    onClick={() => handleMenuClick(option.id)}>
                                    {option.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='m-0' style={{ display: "flex", alignItems: "center", height: "100%" }}>
                        <Divider type='vertical' style={{ backgroundColor: "black", height: "95%" }} />
                    </div>


                    {/* Details Pane */}
                    <div className='border rounded p-2' style={{ backgroundColor: "#fbfbfb", width: "490px" }}>
                        {renderDetails()}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SettingsModal;
