import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../components/AuthContext';
import { API_DOMAIN, cleanStringKeepEmojis, splitBySpecialChar, wati_socket_url } from '../components/constants';

function DashboardV2() {

    // const targetTypes = ["changeOperatorOnlineStatus", "sayWhoIsTyping", "messageFromOperator", "NewChatNotification", "updatingConversation", "sendTextMessage", "catchSelectedConversation", "updatingOperatorMessageStatus", "chatUnassignedFromOperator", "ContactUpdated", "changeBroadcastLinkStatus"];

    // const { authState } = useContext(AuthContext);
    // const wati_token = localStorage.getItem("accessToken");
    // const wati_access_token = localStorage.getItem("chatAccessToken");
    // const [messages, setMessages] = useState([]);
    // const first_message = '{"protocol":"json","version":1}';
    // const heartbeat = '{"type":6}';

    // var list_types = [];
    // useEffect(() => {
    //     // Create a new WebSocket connection
    //     const socket = new WebSocket(wati_socket_url + wati_access_token);


    //     socket.onopen = function (event) {
    //         console.log('Connection established, sending message...');
    //         socket.send(first_message); // Send whatever message the server expects
    //     };

    //     // When a message is received
    //     socket.onmessage = function (event) {
    //         console.log('Message received: ', event.data); // Handling the incoming message
    //         if (event.data == heartbeat) {
    //             setTimeout(() => {
    //                 socket.send(heartbeat);
    //             }, 3000);
    //         }
    //         else {
    //             var data = splitBySpecialChar(event.data);
    //             for (let i = 0; i < data.length; i++) {
    //                 data[i] = cleanStringKeepEmojis(data[i]);
    //                 try { data[i] = JSON.parse(data[i]); } catch (error) { console.log('Error parsing JSON: ', error); }
    //             }

    //             console.log('Data: ', data);

    //         }
    //     };

    //     // Handle errors
    //     socket.onerror = function (event) {
    //         console.error('WebSocket error: ', event);
    //     };

    //     // Handle connection closure
    //     socket.onclose = function (event) {
    //         console.log('Connection closed: ', event);
    //     };

    //     return () => {
    //         socket.close();
    //     };
    // }, []);


    return (
        <div>

        </div>
    );
}

export default DashboardV2;
