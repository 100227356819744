import React, { useContext, useState, useEffect } from 'react';
import {
    LoginOutlined, MessageOutlined, PieChartOutlined,
    RobotOutlined, TeamOutlined, TruckOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import { Avatar, ConfigProvider, Dropdown, Layout, Menu, Space, Spin, theme } from 'antd';
import { AuthContext } from './AuthContext';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import useSnackbar from "../components/Snackbar/UseSnackbar";
import useLogout from './Login/Logout';



const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, path) {
    return { key, icon, label: <Link to={path}>{label}</Link>, path, title: label };
}

const DrawerNew = () => {
    const { handledialogueOpen, LogoutDialog } = useLogout();

    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();
    const { authState } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    function stringAvatar(name) {
        if (name.split(' ').length === 2) {
            // If name has two parts (first name and last name)
            return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
        } else {
            // If name has only one part
            return `${name.split(' ')[0][0].toUpperCase()}`
        }
    }

    // Define menu items
    const items = authState.status
        ? [
            getItem('Dashboard', '/dashboard', <PieChartOutlined />, '/dashboard'),
            getItem('Queryset V3', '/queryset', <WhatsAppOutlined />, '/queryset'),
            getItem('Bot Status', '/WhatsApp', <RobotOutlined />, '/WhatsApp'),
            getItem('WhatsApp Messages', '/whatsapp_messages', <MessageOutlined />, '/whatsapp_messages'),
            getItem('SRM', '/suppliers', <TruckOutlined />, '/suppliers'),
            getItem('HRM', '/hrm', <TeamOutlined />, '/hrm'),
        ]
        : [];

    const [collapsed, setCollapsed] = useState(false);      // Sidebar collapsed state
    const [selectedKey, setSelectedKey] = useState('');     // Menu key state
    const [currentHeader, setCurrentHeader] = useState(''); // Header title state
    const [isLoading, setIsLoading] = useState(true);       // Loading state

    // Handle auth status and navigation
    useEffect(() => {
        if (authState.status === undefined) {
            return;  // Wait for authState to load
        }

        if (!authState.status) {
            // Redirect to login if not authenticated
            navigate('/login');
        } else {
            // Navigate to the last visited page or default to '/dashboard'
            setIsLoading(false);
            const lastPage = location.pathname === '/login' ? '/queryset' : location.pathname;
            navigate(lastPage);
        }
    }, [authState.status, location.pathname, navigate]);

    useEffect(() => {
        setTimeout(() => {
            setCollapsed(true);
        }, 2500);
    }, []);

    // Set menu and header after authentication check
    useEffect(() => {
        if (!authState.status || isLoading) return;

        const setInitialMenuState = () => {
            const currentItem = items.find((item) => item.path === location.pathname);
            if (currentItem) {
                setSelectedKey(currentItem.key);        // Set selected menu key
                setCurrentHeader(currentItem.title);    // Set header title
            } else {
                setSelectedKey('');                     // No key if not found
                setCurrentHeader('Welcome');            // Default title
            }
        };

        setInitialMenuState();
    }, [location.pathname, items, authState.status, isLoading]);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    if (isLoading) {
        return (
            <Layout style={{ minHeight: '100vh', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size="large" />  {/* Loading spinner */}
            </Layout>
        );
    }
    const dropdown_items = [
        {
            key: '1',
            label: "Profile",
        },
        {
            key: '2',
            label: "Reset Password",
        },
        {
            key: '3',
            label: "Logout",
        },
    ];
    const onMenuClick = (e) => {
        switch (e.key) {
            case "1":
                InfoSnackbar(`Keep calm, coffee is being converted to code...`)
                break;
            case "2":
                InfoSnackbar(`Keep calm, coffee is being converted to code...`)
                break;
            case "3":
                handledialogueOpen();
                break;

            default:
                ErrorSnackbar(`Invalid option selected!`)
                break;
        }
    }
    return (
        <ConfigProvider
            theme={{
                // 1. Use dark algorithm
                algorithm: theme.defaultAlgorithm,

                // 2. Combine dark algorithm and compact algorithm
                // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
            }}
        >
            <Layout style={{ minHeight: '100vh', minWidth: '100vw' }}>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "sticky",
                        top: 0,
                        left: 0
                    }}
                >
                    <div className="demo-logo-vertical" />
                    <Menu
                        theme="dark"
                        selectedKeys={[selectedKey]}  // Controlled selected key after load
                        mode="inline"
                        items={items}
                    />
                </Sider>
                <Layout>
                    <div className='flex justify-center sticky top-0 left-0 z-99 drop-shadow'>
                        <Header
                            className='flex justify-between border drop-shadow mb-3'
                            style={{
                                borderRadius: "12px",
                                padding: 10,
                                background: colorBgContainer,
                                width: "99.5%",
                                backgroundColor: "#03224B",
                                color: "white"
                            }}>
                            <h1 className="ml-5">{currentHeader}</h1>
                            <Dropdown
                                className='cursor-pointer'
                                menu={{
                                    items: dropdown_items,
                                    onClick: (e) => onMenuClick(e)
                                }}
                                placement="bottomLeft"
                            >
                                <Tooltip placement='left' title={`Logged in as ${authState?.name || "Unknown User"}`}>
                                    <Avatar size={"large"} className='mr-3' style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{stringAvatar(authState?.name || "Unknown User")}</Avatar>
                                </Tooltip>
                            </Dropdown>



                        </Header>
                    </div>
                    <Content style={{ margin: '0 16px' }}>
                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            <Outlet />
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        UzdPart Used Spare Parts Trading LLC © {new Date().getFullYear()}
                    </Footer>
                </Layout>
            </Layout>
            <LogoutDialog />
        </ConfigProvider>
    );
};

export default DrawerNew;
