import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './components/AuthContext';
import { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { API_DOMAIN } from './components/constants';
import socket from './components/socket'; // Needed for socket initialization
import { CSpinner } from '@coreui/react';
import DrawerNew from './components/DrawerNew';
import GetTitle from './components/GetTitle';
import ChatApp from './components/WatiChatbox/BottomRightChatbox';

// Import Pages
import Landing from './components/Home/Landing';
import Login from './pages/Login';
import DashboardV2 from './pages/DashboardV2';
import Users from './pages/Users';
import QuerysetV2 from './pages/Querysetv2';
import WhatsApp from './pages/WhatsApp';
import WhatsappMessages from './pages/WhatsappMessages';
import Supplier from './pages/Supplier';
import Hrm from './pages/HRM';
import Test from './pages/Test';
import NotFound from './components/Modals/NotFound';

function App() {
  const [authState, setAuthState] = useState({
    username: "",
    id: -1,
    status: false,
    admin: false,
    wati_approval: false
  });

  useEffect(() => {
    axios.get(API_DOMAIN + "/auth/validate", {
      headers: { accessToken: localStorage.getItem("accessToken") }
    }).then((res) => {
      if (res.data.error) {
        setAuthState({ username: "", id: -1, status: false, admin: false, wati_approval: false });
      } else {
        setAuthState(res.data);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Router>
        {authState.status && <ChatApp />} {/* Show ChatApp only for authenticated users */}

        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            {/* Routes for Unauthenticated Users */}
            {!authState.status ? (
              <>
                <Route path="/" element={<Landing />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            ) : (
              /* Authenticated User Routes Wrapped with DrawerNew */
              <Route path="/" element={<DrawerNew title={<GetTitle />} />}>
                <Route path="/login" element={<Navigate to="/querysetv2" />} />
                <Route index element={<DashboardV2 />} />
                <Route path="/dashboard" element={<DashboardV2 />} />
                <Route path="/users" element={<Users />} />
                <Route path="/queryset" element={<QuerysetV2 />} />
                <Route path="/querysetv2" element={<QuerysetV2 />} />
                <Route path="/whatsapp" element={<WhatsApp />} />
                <Route path="/whatsapp_messages" element={<WhatsappMessages />} />
                <Route path="/suppliers" element={<Supplier />} />
                <Route path="/hrm" element={<Hrm />} />
                <Route path="/test" element={<Test />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            )}
          </Routes>
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
