import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { AuthContext } from '../components/AuthContext';
import axios from 'axios';
import { API_DOMAIN, API_ENDPOINTS } from '../components/constants';
import useSnackbar from '../components/Snackbar/UseSnackbar';
import { apiCall } from '../components/Api/Api';
import { Button, Checkbox, Divider, Input, Modal, Select, Space, Table, Tooltip } from 'antd';
import { ClusterOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined, PlusSquareOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import TextArea from 'antd/es/input/TextArea';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StoreIcon from '@mui/icons-material/Store';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

function Dashboard() {

    const deafaultNumberInfo = (<div className='flex justify-center' style={{ color: "gray" }}><i>Please enter a valid whatsapp number above first</i></div>);

    const validatePhoneNumber = (phoneNumber) => {
        try {
            const parsedNumber = parsePhoneNumberFromString("+" + phoneNumber);
            if (!parsedNumber) {
                return { valid: false, message: "Invalid phone number format" };
            }

            if (!parsedNumber.isValid()) {
                return { valid: false, message: "Phone number is not valid for detected country" };
            }

            return {
                valid: true,
                country: parsedNumber.country,
                number: parsedNumber.formatInternational(),
                type: parsedNumber.getType() // e.g., "MOBILE" or "FIXED_LINE"
            };
        } catch (error) {
            console.log(error);
        }
    };

    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

    const [supplierSet, setsupplierSet] = useState([]);
    const [supplierSetFiltered, setsupplierSetFiltered] = useState([]);
    const [validMobileNumber, setValidMobileNumber] = useState(true);

    const [tagSet, setTagSet] = useState([]);
    const [tagSuggestions, setTagSuggestions] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");

    const [whatsapp_number_status, set_whatsapp_number_status] = useState(deafaultNumberInfo);

    //Top-Bar Items
    const [addSupplierModal, setAddSupplierModal] = useState(false);
    const handleAddSupplierOpen = () => {
        reload_supplier_tags();
        setAddSupplierModal(true);
    }
    const handleAddSupplierClose = () => {
        setAddSupplierModal(false);
    }

    //Main Table Loading animation toggle
    const [tableLoading, setTableLoading] = useState(false);

    //supplier formdata
    const [supplierId, setSupplierId] = useState(null);
    const [SupplierMob, setSupplierMob] = useState(null);
    const [SupplierName, setSupplierName] = useState(null);
    const [SupplierContactPersonName, setSupplierContactPersonName] = useState(null);
    const [SupplierAddress, setSupplierAddress] = useState(null);
    const [SupplierTags, setSupplierTags] = useState([]);

    //Loading bool and misc flags
    const [supplierAddEditModalTitle, setSupplierAddEditModalTitle] = useState("Add New Suppplier")
    const [addModalButtonLoading, setAddModalButtonLoading] = useState(false)
    const [tagManagementButtonLoading, setTagManagementButtonLoading] = useState(false)
    const [supplier_tag_loading, setSupplier_tag_loading] = useState(false)


    //clear supplier add form data
    const clearSupplierFormData = () => {
        setSupplierId(null);
        setSupplierMob(null);
        setSupplierName(null);
        setSupplierContactPersonName(null);
        setSupplierAddress(null);
        setSupplierTags([]);
        setCurrentSupplierInfo([])
    }


    //Delete supplier
    const [deleteFinalSupplierModal, setDeleteFinalSupplierModal] = useState(false);
    const [currentSupplierInfo, setCurrentSupplierInfo] = useState([]);
    const [supplierDisableReason, setSupplierDisableReason] = useState(null);

    //check all tags controllers
    const checkAll = tagSuggestions?.length === (SupplierTags.length);
    const indeterminate = (SupplierTags.length) > 0 && (SupplierTags.length) < tagSuggestions?.length;

    const onCheckAllChange = (e) => {
        setSupplierTags(e.target.checked ? tagSuggestions : []);
    };
    const handleSupplierTagChange = (selectedTagIds) => {
        setSupplierTags(tagSuggestions.filter(e => selectedTagIds.includes(e.value)))
    }
    const handledeleteFinalSupplierModalOpen = () => {
        setDeleteFinalSupplierModal(true)
    }
    const handledeleteFinalSupplierModalClose = () => {
        setDeleteFinalSupplierModal(false)
    }
    const handleDeleteSupplier = (supplier) => {
        setCurrentSupplierInfo(supplier)
        handledeleteFinalSupplierModalOpen();
    }
    const closeDelteSupplier = () => {
        setSupplierDisableReason(null);
        setCurrentSupplierInfo([]);
        handledeleteFinalSupplierModalClose();
    }
    const handleSupplierDelete = async () => {
        if (!supplierDisableReason) {
            WarningSnackbar(`Please enter a reason for disabling this supplier`)
        }
        else {
            try {
                const response = await apiCall({
                    api_key: "uzdpart",
                    id: currentSupplierInfo.id,
                    reason: supplierDisableReason,
                }, API_ENDPOINTS.disable_suppliersV2, "post");
                if (response.success) {
                    setsupplierSet(prevState => prevState.filter(e => e.id !== currentSupplierInfo.id));
                    SuccessSnackbar(`Deleted supplier successfully`)
                    closeDelteSupplier();
                }
                else {
                    ErrorSnackbar(`Failed to delete supplier`)
                }
            } catch (error) {
                ErrorSnackbar(error.message);
            }
        }
    }
    const closeSupplierAddModalAdv = () => {
        clearSupplierFormData();
        set_whatsapp_number_status(deafaultNumberInfo)
        handleAddSupplierClose();
        setCurrentSupplierInfo([]);
        setSupplierAddEditModalTitle(`Add New Suppplier`);
    }

    //Edit supplier
    const handleEditSupplier = (record) => {
        setSupplierAddEditModalTitle(`Edit Info of Supplier '${record?.id}. ${record?.name}'`);

        setSupplierName(record?.name)
        setSupplierContactPersonName(record?.contact_person);
        setSupplierAddress(record?.address);
        setSupplierTags(record?.tag);
        setSupplierMob(record?.number);

        handleAddSupplierOpen();
        setSupplierId(record?.id);
    }
    const reload_supplier_data = async () => {
        try {
            setTableLoading(true);
            const response = await apiCall({ api_key: "uzdpart", search: searchTerm, }, API_ENDPOINTS.get_suppliersV2, "post");
            if (response.success) {
                setsupplierSet(response.data);
                SuccessSnackbar("Fetched " + response.data.length + " Search result");
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
        finally {
            setTableLoading(false);
        }
    };
    const reload_supplier_tags = async () => {
        setSupplier_tag_loading(true);
        try {
            var res = await axios.post(API_DOMAIN + "/whatsapp/get_supplier_tags", { api_key: "uzdpart" })
            if (res.data.success) {
                setTableLoading(false);
                setTagSet(res.data.data);
                var tagSetTemp = res.data.data.map((item, index) => ({
                    value: item.id,
                    label: item.name
                }));
                setTagSuggestions(tagSetTemp);
            }

        } catch (error) {
            setTableLoading(false);
            ErrorSnackbar(error.message);
        }
        finally {
            setSupplier_tag_loading(false);
        }
    };
    const loadTags = async () => {
        setTagManagementButtonLoading(true);
        try {
            const response = await apiCall({
                api_key: "uzdpart"
            }, API_ENDPOINTS.get_supplier_tags, "post");
            if (response.success) {
                setTagSet(response.data);
                setFilteredTagsTable(response.data);
                SuccessSnackbar("Fetched " + response.data.length + " Tags");
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
        finally {
            setTagManagementButtonLoading(false);
        }
    };
    const handleTagManagement = async () => {
        await loadTags();
        //open the tag main model if it is not in the add supplier modal
        if (!addSupplierModal)
            handleTagMainModalOpen();
    }
    const supplier_mobile_validity_check = async (number) => {
        try {
            const response = await apiCall({ api_secret: "uzdpart", phone: number }, API_ENDPOINTS.get_wa_status, "post");

            if (response?.success) {
                switch (response?.success) {
                    case 0:
                        break;
                    case 1:
                        if (supplierAddEditModalTitle.includes(`Edit Info`)) {
                            setValidMobileNumber(true)
                            set_whatsapp_number_status((
                                <div>
                                    <div className='mb-2' style={{ color: "green" }}>This Supplier has a valid Whatsapp Number</div> <b>{response?.data?.[0]?.name || <i>Name Not Added</i>}</b>&nbsp;(ID : {response?.data?.[0]?.id})
                                    <div>{response?.data?.[0]?.contact_person || (<i>Contact Person Name Not Added</i>)},</div>
                                    <div>{response?.data?.[0]?.address || (<i>Address Not Added</i>)}</div>
                                </div>))
                        }
                        else {
                            setValidMobileNumber(false)
                            set_whatsapp_number_status((
                                <div>
                                    <div className='mb-2' style={{ color: "#d4bc33" }}>The number Already exists in Database as</div> <b>{response?.data?.[0]?.name || <i>Name Not Added</i>}</b>&nbsp;(ID : {response?.data?.[0]?.id})
                                    <div>{response?.data?.[0]?.contact_person || (<i>Contact Person Name Not Added</i>)},</div>
                                    <div>{response?.data?.[0]?.address || (<i>Address Not Added</i>)}</div>
                                </div>))
                        }
                        break;
                    case 2:
                        if (response?.data?.numberExists) {
                            setValidMobileNumber(true);
                            set_whatsapp_number_status((<div style={{ color: "green" }}>The number is <b>Available</b> on WhatsApp</div>))
                        }
                        else {
                            setValidMobileNumber(false);
                            set_whatsapp_number_status((<div style={{ color: "#ff7c73" }}>The number is <b>Unavailable</b> on WhatsApp</div>))
                        }
                        break;
                    default:
                        break;
                }
            }


            // if (!response.success && response.data.length > 0) {
            //     wa_status = "Supplier Already Exists (" + response.data[0].name + ")";
            //     setformValid(false);
            //     document.getElementById("wa_valid").style = "color:red;";
            // }
            // if (response.success && response.data.status == 200) {
            //     wa_status = "YES";
            //     setformValid(true);
            //     document.getElementById("wa_valid").style = "color:green;text-align:right;padding-right:10px;";
            // }
            // if (response.success && response.data.status == 404) {
            //     wa_status = "NO";
            //     setformValid(false);
            //     document.getElementById("wa_valid").style = "color:red;text-align:right;padding-right:10px;";
            // }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };
    const handleSupAdd = async () => {
        try {
            setAddModalButtonLoading(true);
            if (!validMobileNumber) {
                WarningSnackbar(`Enter a valid phone number`);
                return;
            }
            else if (!SupplierName) {
                WarningSnackbar(`Enter a valid Supplier Name`);
                return;
            }
            else if (SupplierTags?.length == 0) {
                WarningSnackbar(`Select correct Tags for the supplier`);
                return;
            }
            else {
                const res = await axios.post(API_DOMAIN + "/whatsapp/add_suppliersV2", {
                    api_key: "uzdpart", name: SupplierName?.trim(), number: SupplierMob, address: SupplierAddress?.trim(), tag: SupplierTags, id: supplierId, contact_person: SupplierContactPersonName?.trim()
                })

                if (res.data.success) {
                    setsupplierSet(res.data.data);
                    SuccessSnackbar(res.data.message);
                    closeSupplierAddModalAdv();
                }
                else {
                    ErrorSnackbar(res.data.message);
                }
            }
        } catch (error) {
            ErrorSnackbar(error.message)
        }
        finally {
            setAddModalButtonLoading(false);
        }
    };

    useEffect(() => {
        reload_supplier_data();
        reload_supplier_tags();
    }, []);

    const supplierTableColumns = [
        {
            title: 'Sl. No.',
            render: (text, record, index) => index + 1,
            width: "5%"
        },
        {
            title: 'Supplier Name',
            render: (record) => (
                <React.Fragment>
                    <div className="flex items-center">
                        {!record?.tag?.length != 0 ? (
                            <Tooltip title="This suppplier doesn't have any tags, it never gets any queries passed.">
                                <div style={{
                                    width: "8px",
                                    height: "8px",
                                    backgroundColor: "red",
                                    borderRadius: "5px"
                                }} />
                            </Tooltip>
                        ) : null}
                        &nbsp;
                        {record?.name}
                    </div>
                </React.Fragment>
            )
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person',
        },
        {
            title: 'Supplier Address',
            dataIndex: 'address',
        },
        {
            title: 'Supplier Number',
            dataIndex: 'number',
        },
        {
            title: 'Tags',
            render: (record) => (
                <React.Fragment>
                    <div
                        style={{
                            height: "50px",
                            overflowY: "auto",
                            scrollSnapType: "y mandatory",
                            scrollBehavior: "smooth",
                            display: "block",
                        }}
                    >
                        {record?.tag?.length == 0 ? (<div className='flex justify-center' style={{ fontSize: "12px", color: "gray" }}><i>No tags Added</i></div>) : record?.tag?.map((item, tagIndex) => (
                            <div key={tagIndex} style={{ scrollSnapAlign: "start" }}>
                                {tagIndex + 1}.&nbsp;{item.label}
                            </div>
                        ))}
                    </div>
                    <div style={{ fontSize: "11px", color: "#777" }} className="flex justify-end pr-1">
                        <Tooltip title="Scroll inside the parts-list to see all the tags.">
                            Total :&nbsp;{record?.tag?.length || 0}
                        </Tooltip>
                    </div>
                </React.Fragment>
            )
        },
        {
            title: 'Actions',
            render: (text, record, index) => (
                <div className='flex justify-between pl-2 pr-2'>
                    <Tooltip placement="left" title="Edit Supplier information.">
                        <EditOutlined onClick={() => handleEditSupplier(record)} className="cursor-pointer hover:scale-125 active:scale-90 transition-transform duration-100 ease-in-out" />
                    </Tooltip>

                    <Tooltip placement="top" title="Delete this Supplier.">
                        <DeleteOutlined style={{ color: "#ff7c73" }} className="cursor-pointer hover:scale-125 active:scale-90 transition-transform duration-100 ease-in-out" onClick={() => handleDeleteSupplier(record)} />
                    </Tooltip>

                </div>
            ),
            width: "6%"
        },
    ]

    const tagsTableColumns = [
        {
            title: 'Sl. No.',
            render: (text, record, index) => index + 1,
            width: "5%"
        },
        {
            title: 'Tag Name',
            render: (text, record, index) => (
                <div className="flex items-center">
                    {!record?.tagged_suppliers?.length != 0 ? (
                        <Tooltip title="This tag doesn't have any Suppliers, it never gets any queries passed.">
                            <div style={{
                                width: "8px",
                                height: "8px",
                                backgroundColor: "red",
                                borderRadius: "5px"
                            }} />
                        </Tooltip>
                    ) : null}
                    &nbsp;
                    {record?.name}
                </div>
            )
        },
        {
            title: 'Tagged Suppliers',
            render: (record) => (
                <React.Fragment>
                    <div
                        style={{
                            height: "65px",
                            overflowY: "auto",
                            scrollSnapType: "y mandatory",
                            scrollBehavior: "smooth",
                            display: "block",
                        }}
                    >
                        {record?.tagged_suppliers == null || (record?.tagged_suppliers?.length === 1 && record?.tagged_suppliers[0] === null) ? (
                            <div className='flex justify-center' style={{ fontSize: "12px", color: "gray" }}>
                                <i>No suppliers tagged</i>
                            </div>
                        ) : record?.tagged_suppliers?.map((item, tagIndex) => (
                            <div key={tagIndex} style={{ scrollSnapAlign: "start" }}>
                                {tagIndex + 1}. {item}
                            </div>
                        ))}

                    </div>
                    <div style={{ fontSize: "11px", color: "#777" }} className="flex justify-end pr-1">
                        <Tooltip title="Scroll inside the parts-list to see all the tags.">
                            Total :&nbsp;{record?.tagged_supplier_count || 0}
                        </Tooltip>
                    </div>
                </React.Fragment>
            )
        },
        {
            title: 'Actions',
            render: (text, record, index) => (
                <div className='flex justify-center pl-2 pr-2'>
                    <Tooltip placement="top" title="Delete this Tag.">
                        <DeleteOutlined style={{ color: "#ff7c73" }} className="cursor-pointer hover:scale-125 active:scale-90 transition-transform duration-100 ease-in-out" onClick={() => handleTagDelete(record)} />
                    </Tooltip>

                </div>
            ),
            width: "6%"
        }
    ]

    useEffect(() => {

        if (SupplierMob) {
            const number_validity = validatePhoneNumber(SupplierMob)
            if (number_validity?.valid) {
                supplier_mobile_validity_check(SupplierMob);
            }
            else {
                set_whatsapp_number_status(deafaultNumberInfo);
            }
        }
        else {
            set_whatsapp_number_status(deafaultNumberInfo);
        }
    }, [SupplierMob]);


    //Tag Management Main Modal
    const [tagMainModalView, setTagMainModalView] = useState(false);
    const handleTagMainModalOpen = () => {
        setTagMainModalView(true);
    }
    const handleTagMainModalClose = () => {
        setTagMainModalView(false);
    }

    const [searchTag, setSearchTag] = useState(null);
    const [filteredTagsTable, setFilteredTagsTable] = useState([]);

    const handleTagSearch = (value) => {
        setSearchTag(value);
        setFilteredTagsTable(value ? tagSet.filter(e => e.name.toLowerCase().includes(value?.toLowerCase())) : tagSet);
    }


    //Add new tag modal and related stuff
    const [tagAddModalView, setTagAddModalView] = useState(false);
    const [newTagValue, setNewTagValue] = useState(null);
    const [addTagSaveButtonLoading, setAddTagSaveButtonLoading] = useState(false);

    const handleTagAddModalOpen = () => {
        setTagAddModalView(true);
    }
    const handleTagAddModalClose = () => {
        setTagAddModalView(false);
    }
    const handleTagAddModalCloseAdv = () => {
        setNewTagValue(null);
        setTagAddModalView(false);
    }

    const handleTagAddAction = async () => {
        try {
            const response = await apiCall({
                api_key: "uzdpart",
                tag: newTagValue?.trim()
            }, API_ENDPOINTS.add_supplier_tags, "post");
            if (response.success) {
                SuccessSnackbar("Added new tag!");
                setNewTagValue(null);
                return 1;
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };

    const handleTagAdd = async () => {
        if (!newTagValue) {
            WarningSnackbar(`Please enter a valid Tag name`)
            return;
        }
        setAddTagSaveButtonLoading(true);
        if (addSupplierModal) {
            try {
                const response = await handleTagAddAction();
                if (response) {
                    handleTagAddModalCloseAdv();
                    await reload_supplier_tags();
                }
            } catch (error) {
                ErrorSnackbar(error.message);
            }
            finally {
                setAddTagSaveButtonLoading(false);
            }
        } else {
            try {
                const response = await handleTagAddAction();
                if (response) {
                    handleTagAddModalCloseAdv();
                    setTableLoading(true);
                    await handleTagManagement();
                    setTableLoading(false);
                }
            } catch (error) {
                ErrorSnackbar(error.message);
            }
            finally {
                setAddTagSaveButtonLoading(false);
                setSearchTag(null);
            }
        }
    }

    const handleTagDelete = async (tag) => {
        if (!tag)
            ErrorSnackbar(`Not a valid tag`)
        else {
            try {
                setTableLoading(true);
                const res = await handleTagDeleteAction(tag);
                if (res) {
                    handleTagAddModalClose();
                    await handleTagManagement();
                }

            } catch (error) {
                ErrorSnackbar(error.message);
            }
            finally {
                setTableLoading(false);
                setSearchTag(null);
            }
        }
    }

    const handleTagDeleteAction = async (tag) => {
        const confirmDelete = window.confirm(`${tag?.tagged_supplier_count} suppliers are tagged with '${tag.name}'. Are you sure you want to delete this Tag?`);

        if (!confirmDelete) return;

        try {
            await deleteTag(tag.id);
            SuccessSnackbar("Deleted Tag Successfully");
            return true;
        } catch (error) {
            ErrorSnackbar(error.message);
            return false;
        }
    };
    const deleteTag = async (id) => {
        try {
            const response = await apiCall({
                api_key: "uzdpart",
                id: id
            }, API_ENDPOINTS.delete_supplier_tags, "post");

            if (response.success) {
                return response.data;
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };
    return (
        <>
            <div className="flex justify-between drop-shadow" style={{ width: "100%", backgroundColor: "#20c768", border: "1px solid #20c768", borderRadius: "5px", overflowY: "auto", position: "sticky", top: 70, zIndex: "1000", }}>
                <div className="flex p-2.5 flex-wrap gap-1.5" style={{ minWidth: "80%" }}>
                    <Input
                        style={{ width: "225px" }}
                        allowClear
                        placeholder="Search Name, Number, Tag"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={(event) => { if (event.key === 'Enter') { reload_supplier_data(event.target.value); } else if (event.key === 'Escape') { setSearchTerm(null) } }}
                    />

                    <Button style={{ width: "60px" }} type="primary" onClick={() => reload_supplier_data()}>
                        <SearchOutlined />
                    </Button>
                </div>
                <div className="flex justify-end p-2.5 gap-5 flex-wrap" style={{ width: "20%" }}>
                    <div className="flex flex-wrap gap-1.5">
                        <Tooltip title="Add new Supplier">
                            <Button style={{ width: "60px" }} type="primary" onClick={handleAddSupplierOpen}>
                                <PlusSquareOutlined />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Manage Tags">
                            <Button loading={tagManagementButtonLoading} style={{ width: "60px" }} type="primary" onClick={handleTagManagement}>
                                <ClusterOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className='p-2'>
                Total Suppliers Loaded : <b>{supplierSet?.length || 0}</b>
            </div>

            <div>
                <Table
                    rowKey={(record) => record.id}
                    dataSource={supplierSet}
                    className="drop-shadow border rounded"
                    style={{ width: "100%", minWidth: "70%", overflowX: "auto", backgroundColor: "unset !important" }}
                    columns={supplierTableColumns}
                    loading={tableLoading}
                    bordered={true}
                    pagination={{
                        position: ["topRight", "bottomRight"],
                    }}
                />
            </div>

            {/* Add Supplier Modal */}
            <Modal
                title={supplierAddEditModalTitle}
                open={addSupplierModal}
                onCancel={closeSupplierAddModalAdv}
                centered
                width={840}
                maskClosable={false}
                footer={< div className="flex justify-end" >
                    <div>
                        <Button
                            variant="contained"
                            loading={addModalButtonLoading}
                            style={{ backgroundColor: "green", color: "white" }}
                            onClick={handleSupAdd}
                        ><SaveOutlined />Save
                        </Button>
                    </div>
                </div >}>
                <div className="flex border rounded drop-shadow p-2" style={{ backgroundColor: "#f7f7f7" }}>
                    <div className="gap-2 grid p-2" style={{ maxWidth: "50%" }}>
                        <div >
                            <Input
                                placeholder="Whatsapp Number"
                                allowClear
                                required
                                focus={true}
                                value={currentSupplierInfo?.number || SupplierMob}
                                onChange={(e) => {
                                    const onlyNumbers = e.target.value.replace(/\D/g, "");
                                    setSupplierMob(onlyNumbers);
                                }}
                            />
                            <div style={{ color: "gray", fontSize: "12px" }}>
                                <i>Please add country code to the number and avoid adding the + sign</i>
                            </div>
                            <div className='border rounded p-2 mt-2 drop-shadow'>
                                <div className='flex justify-center'>
                                    IS THIS NUMBER AVAILABLE ON WHATSAPP ?
                                </div>
                                <div className='mt-3'>
                                    <div className='border rounded p-2' style={{ backgroundColor: "#fbfbfb", height: "150px", overflowY: "auto" }}>
                                        {whatsapp_number_status}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Divider style={{ height: "auto" }} type="vertical" />

                    <div style={{ width: "50%" }} className='p-2'>
                        <Input
                            placeholder="Name of the Supplier"
                            allowClear
                            required
                            value={SupplierName}
                            onChange={(e) => {
                                setSupplierName(e.target.value);
                            }}
                        />
                        <Input
                            placeholder="Name of Contact Person"
                            className='mt-2'
                            allowClear
                            required
                            value={SupplierContactPersonName}
                            onChange={(e) => {
                                setSupplierContactPersonName(e.target.value);
                            }}
                        />
                        <TextArea
                            className='mt-2'
                            placeholder="Address of the Supplier"
                            allowClear
                            required
                            value={SupplierAddress}
                            onChange={(e) => {
                                setSupplierAddress(e.target.value);
                            }}
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />
                        <div>

                            <div className='flex justify-between mt-2 mb-2 align-middle mr-1 ml-1' style={{ color: "gray", fontSize: "10px" }}>
                                <i>Total Tags Selected :&nbsp;<b>{SupplierTags?.length || 0}</b></i>
                                <Tooltip title="Select All Tags at once" placement="right">
                                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                    </Checkbox>
                                </Tooltip>
                            </div>
                            <div className='flex justify-between supplier-add-modal-tags' style={{ width: "100%" }}>
                                <Space>
                                    <Space.Compact>
                                        <Select
                                            className='supplier-add-tag'
                                            mode="multiple"
                                            allowClear
                                            style={{ overflowY: "auto" }}
                                            optionFilterProp="label"
                                            placeholder="Select Supplier Tags"
                                            value={SupplierTags}
                                            onChange={(e) => handleSupplierTagChange(e)}
                                            options={tagSuggestions}
                                            loading={supplier_tag_loading}
                                        />
                                        <Tooltip title="Add a new Tag" placement="left">
                                            <Button onClick={handleTagAddModalOpen}>+</Button>
                                        </Tooltip>
                                    </Space.Compact>
                                </Space>


                            </div>
                        </div>
                    </div>


                </div>
            </Modal >

            {/* Delete Supplier Modal */}
            <Modal
                title={`Delete Supplier`}
                open={deleteFinalSupplierModal}
                onCancel={handledeleteFinalSupplierModalClose}
                centered
                width={700}
                maskClosable={false}
                footer={< div className="flex justify-end" >
                    <div>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#ff7c73", color: "white" }}
                            onClick={handleSupplierDelete}
                        ><DeleteOutlined />Delete
                        </Button>
                    </div>
                </div >}>

                <div className='border flex justify-between rounded p-2' style={{ backgroundColor: "#f7f7f7" }}>
                    <div className='border rounded m-2 p-2' style={{ width: "500px" }}>
                        <div className='flex justify-center' style={{ fontSize: "16px" }}><u>SUPPLIER INFORMATION</u></div>

                        <div className='mt-2 ml-2' style={{ fontSize: "10px", color: "gray" }}><i>ID :&nbsp;<b>{currentSupplierInfo?.id}</b></i></div>
                        <div className='border rounded p-2' style={{ backgroundColor: "#fbfbfb" }}>
                            <div className='mt-2'><WhatsAppIcon className='mr-2' /><b>{currentSupplierInfo?.number}</b></div>
                            <div className='mt-2'><StoreIcon className='mr-2' /><b>{currentSupplierInfo?.name}</b></div>
                            <div className='mt-2'><PersonOutlineIcon className='mr-2' />{currentSupplierInfo?.contact_person || (<i style={{ color: "gray" }}>Contact person name not added</i>)}</div>
                            <div className='mt-2'><PlaceIcon className='mr-2' />{currentSupplierInfo?.address || (<i style={{ color: "gray" }}>Address not added</i>)}</div>
                        </div>


                        <div className='border rounded mt-2 p-2' style={{ width: "274px", height: "80px", overflowY: "auto", backgroundColor: "#fbfbfb" }}>
                            {currentSupplierInfo?.tag?.length > 0 ? currentSupplierInfo?.tag?.map((tag, index) => (<div>{index + 1}.&nbsp;{tag?.label}</div>)) : (<i style={{ color: "gray" }}>No tags were added</i>)}
                        </div>
                        <div className='flex justify-end pr-2' style={{ fontSize: "10px", color: "gray" }}>Total tags :&nbsp;{currentSupplierInfo?.tag?.length || 0}</div>
                    </div>
                    <div className='border rounded m-2 p-2'>
                        <div className='mt-2 ml-1'>Reason for disabling the Supplier </div>
                        <TextArea
                            className='mb-2'
                            placeholder={null}
                            allowClear
                            required
                            autoFocus={true}
                            value={supplierDisableReason}
                            onChange={(e) => {
                                setSupplierDisableReason(e.target.value);
                            }}
                            autoSize={{
                                minRows: 8,
                                maxRows: 10,
                            }}
                        />
                        <div className='p-2'><InfoCircleOutlined />&nbsp;This Supplier will be <b style={{ color: "red" }}>Disabled</b> and future Queries <b style={{ color: "red" }}>will not be shared</b> to them.</div>
                    </div>
                </div>

            </Modal >

            {/* Tag Management Main Modal */}
            <Modal
                title={`Manage Tags`}
                open={tagMainModalView}
                onCancel={handleTagMainModalClose}
                centered
                width={700}
                maskClosable={false}
                footer={< div className="flex justify-center" >
                    Total Number of Tags in Database :&nbsp;<b>{tagSet?.length || 0}</b>
                </div >}>
                <div className='border rounded m-2 p-3' style={{ backgroundColor: "#f7f7f7" }}>
                    <div className='flex justify-between'>
                        <Input
                            style={{ width: "85%" }}
                            allowClear
                            className='mb-2'
                            placeholder="Search Tag"
                            value={searchTag}
                            onChange={(e) => handleTagSearch(e.target.value)}
                            onKeyDown={(event) => { if (event.key === 'Enter') { reload_supplier_data(event.target.value); } else if (event.key === 'Escape') { setSearchTerm(null) } }}
                        />
                        <Tooltip title="Add new Tag">
                            <Button style={{ width: "10%" }} type="primary" onClick={handleTagAddModalOpen}>
                                <PlusOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                    <Table
                        rowKey={(record) => record.id}
                        dataSource={filteredTagsTable}
                        className="drop-shadow border rounded"
                        style={{ width: "100%", minWidth: "70%", overflowX: "auto", backgroundColor: "unset !important", height: "60vh" }}
                        columns={tagsTableColumns}
                        bordered={true}
                        pagination={false}
                        loading={tableLoading}
                    />
                </div>

            </Modal >

            {/* Tag Add Modal */}
            <Modal
                title={`Add new Tag`}
                open={tagAddModalView}
                onCancel={handleTagAddModalCloseAdv}
                centered
                width={500}
                maskClosable={false}
                footer={
                    < div className="flex justify-end" >
                        <Button
                            variant="contained"
                            loading={addTagSaveButtonLoading}
                            style={{ backgroundColor: "green", color: "white" }}
                            onClick={handleTagAdd}
                        ><SaveOutlined />Save
                        </Button>
                    </div >
                }>
                <div className='border rounded p-3' style={{ backgroundColor: "#f7f7f7" }}>
                    <Input
                        allowClear
                        placeholder="Enter Tag Name"
                        value={newTagValue}
                        onChange={(e) => setNewTagValue(e.target.value)}
                    />
                </div>
            </Modal >

            <ToastContainer />
        </ >

    );

}

export default Dashboard
